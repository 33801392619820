import React from 'react';
import Sidebar from './components/Sidebar';
import { useNavigate } from 'react-router-dom';

export default function GettingStarted() {
    const navigate = useNavigate();

    return(
        <div className='getting-started-main-content'>
            <Sidebar />
            <div className='main-content'>
                <h2>
                    Welcome to Propicks!
                </h2>
                <p style={{fontSize: 16, width: '80%'}}>
                    We're happy you've decided to join us! This page contains some information that can help you better 
                    understand ProPicks and sports betting. Below, you'll find guides on how to use our social media platform, 
                    how to use the tools we provide, and other helpful information about sports betting. If you just want to 
                    jump in to things, go ahead and <span onClick={() => {navigate('/signin')}}>create an account</span> to 
                    join the conversation!
                </p>
                <h4>
                    Guides:
                </h4>
                <ul>
                    <li>Intro to Sports Betting</li>
                    <li>ProPicks Basics</li>
                    <li>ProPicks Tools</li>
                    <li>Getting an Edge on the Market</li>
                    <li>Account FAQs</li>
                </ul>
                <p>This page is not currently finished, more information coming soon!</p>
            </div>
        </div>
    )
}