import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import './style-pages/checkstatus.css';

export default function CheckStatus() {
    const [confirmationStatus, setConfirmationStatus] = useState(false);
    const navigate = useNavigate();
    const stripe = useStripe();
    const auth = getAuth();
    
    useEffect(() => {

        const search = new URLSearchParams(window.location.search);
        const tier = search.get('tier');

        const setupSecret = search.get('setup_intent_client_secret');

        console.log("The secret is: " + setupSecret);

        if(stripe) {
            stripe.retrieveSetupIntent(setupSecret)
                .then(async ({setupIntent}) => {
                    switch(setupIntent.status) {
                        case 'processing':
                            console.log("Still Processing...");
                            break;
                        case 'succeeded':
                            onAuthStateChanged(auth, async (user) => {
                                if (user) {
                                    console.log(setupIntent)
                                    await axios.post("https://propicks-data-api.com/create-subscription", {
                                        intent_id: setupIntent.id,
                                    })
                                        .then(() => {
                                            navigate("/");
                                        })
                                        .catch((err) => {
                                            console.log({ message: err.message })
                                        })
                                }
                            })
                            
                    }
                })
        } else {
            console.log("Stripe not yet loaded in");
        }
    }, [stripe]);

    return(
        <div>
            <p id="thanksTitle" >
                Thank you for joining ProPicks!
            </p>
            <p id="systemNudgeTitle">
                If you are not redirected within a few seconds, click the button below:
            </p>
            <button id="systemNudge" >
                Reload
            </button>
        </div>
    );
}
