import React, { useState, useEffect, useReducer, useCallback } from 'react'
import Sidebar from './components/Sidebar'
import axios from 'axios';
import './style-pages/chat.css'
import { useNavigate, createSearchParams  } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';

import heart_white from './assets/heart_white.svg';
import heart_red from './assets/heart_red.svg';
import reply from './assets/reply.svg';
import flag from './assets/flag.svg';
import share from './assets/share.svg';

export default function Chat() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [chatId, setChatId] = useState();
    const [chat, setChat] = useState({});
    const [chatReplies, setChatReplies] = useState([]);
    const [dataExists, setDataExists] = useState(false);
    const [repliesExist, setRepliesExist] = useState(false);
    const [user, setUser] = useState();
    const [userMessage, setUserMessage] = useState();
    const [bettingLine, setBettingLine] = useState();
    const [bettingLineExists, setBettingLineExists] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [placeHolder, setPlaceHolder] = useState("Type something to reply...");
    const [authenticated, setAuthenticated] = useState(false);

    const respondToMessage = async (origin_message_id) => {
        const date = new Date();
        const uuid = uuidv4();

        const chat = {
            message_id: uuid,
            origin_message_id: origin_message_id,
            user_name: user.name,
            user_gid: user.gid,
            message_data: userMessage,
            post_date: date,
            chat_stream_id: null,
            forum_name: null,
            betting_line: null,
            is_reply: true,
            comment_count: 0,
            is_liked: false,
            like_count: 0
        }

        try {
            const response = await axios.post("https://propicks-social-api.com/post-reply", {
                chat: chat,
            })

            setChatReplies(chatReplies => [...chatReplies, chat])
            setUserMessage('')
        } catch (err) { console.log({err: err.message}) }

        await axios.post("https://propicks-data-api.com/add-user-post", {
            "user_gid": user.gid,
            "message_id": uuid,
        })
            .catch((err) => {
                console.log({ "message": err.message });
            })

        forceUpdate();
    }

    const likeMessage = async (message_id) => {
        try {
            const response = await axios.post("https://propicks-data-api.com/like-post", {
              post_id: message_id,
              user_id: user.gid,
            })
    
            if(response.status == 200){
              handleLike(message_id)
            } else {
              console.log("There was an error...")
            }
        } catch (err) {
            console.log({ err: err.message })
        }
    }

    const handleLike = () => {
        const isLiked = !chat.is_liked;
        setChat({...chat, is_liked: isLiked, liked_count: chat.liked_count + (isLiked ? 1 : -1)})
    }

    const likeReply = async (message_id) => {
        try {
            const response = await axios.post("https://propicks-data-api.com/like-post", {
              post_id: message_id,
              user_id: user.gid,
            })
    
            if(response.status == 200){
              handleReplyLike(message_id)
            } else {
              console.log("There was an error...")
            }
        } catch (err) {
            console.log({ err: err.message })
        }
    }

    const handleReplyLike = (message_id) => {
        setChatReplies((chatReplies) =>
            chatReplies.map((chat) => {
                if (chat.message_id === message_id) {
                  const isLiked = !chat.is_liked;
                  return { ...chat, is_liked: isLiked, like_count: chat.like_count + (isLiked ? 1 : -1) };
                }
                return chat;
            })
        );
        forceUpdate()
    };

    const openUserProfile = (user_gid) => {
        const newParams = createSearchParams({
            gid: user_gid
        });
        
        navigate({
            pathname: '/userProfile',
            search: `?${newParams.toString()}`
        });
    }

    const getChatInfo = async (liked_posts) => {
        const queryParams = new URLSearchParams(window.location.search)
        const id = queryParams.get("id")
        setChatId(id);

        console.log(liked_posts)

        try {
            const response = await axios.post("https://propicks-data-api.com/get-chat", {
                chat_id: id
            })

            if(liked_posts){
                if(liked_posts.includes(response.data.message_id)){
                    response.data.is_liked = true;
                }
            }

            console.log(response.data.is_liked)

            setChat(response.data);
            setDataExists(true);

            if(response.data.betting_line){
                setBettingLine(JSON.parse(response.data.betting_line))
                setBettingLineExists(true)
            }

            console.log("Chat loaded")
        } catch (err) {
            console.log({ err: err.message })
        }

        try {
            const response = await axios.post("https://propicks-data-api.com/get-replies", {
                origin_message_id: id
            })
    
            const replies = response.data.reply_data;

            if(liked_posts && replies){
                replies.map((reply) => {
                    if(liked_posts.includes(reply.message_id)){
                        reply.is_liked = true;
                    }
                })
            }
    
            setChatReplies(response.data.reply_data)
            setRepliesExist(true)

            console.log("Replies loaded... if any exist.")
        } catch (err) {
            console.log({ err: err.message })
        }
    }

    const shareChat = (message_id) => {
        const link = "https://propicks.io/chat?id=" + message_id;
        navigator.clipboard.writeText(link)
    }

    const auditBetKey = (bet_key) => {
        if(bet_key == 'h2h') return "Moneyline"
        else if (bet_key == "spreads") return "Spread"
        else if (bet_key == "totals") return "Totals"
    }
  
    const auditBetBook = (bet_book) => {
        if(bet_book == "fanduel") return "Fanduel"
        else if (bet_book == "draftkings") return "DraftKings"
        else if (bet_book == "mybookieag") return "MyBookieAG"
        else if (bet_book == "betrivers") return "BetRivers"
        else if (bet_book == "betmgm") return "BetMGM"
        else if (bet_book == "bovada") return "Bovada"
        else if (bet_book == "lowvig") return "LowVig"
        else if (bet_book == "betus") return "BetUS"
        else return bet_book;
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          postMessage();
        }
    };

    const handleInputChange = (event) => {
        setUserMessage(event.target.value);
    };

    const openTeamFeed = (chat_id) => {
        const newParams = createSearchParams({
            id: chat_id
        });
        
        navigate({
            pathname: '/teamchat',
            search: `?${newParams.toString()}`
          });
    };

    const flagPost = async (chat_id) => {
        try {
            await axios.post("https://propicks-data-api.com/flag-post", {
                message_id: chat_id,
            })
        } catch (err) {
            console.log({ err: err.message })
        }
    }

    const getUserInfo = useCallback(async (gid) => {
        if (session != null) {
            const userInfo = JSON.parse(session)
            const queryParams = new URLSearchParams(window.location.search)
            const id = queryParams.get("id")

            setUser(userInfo);

            if(userInfo.liked_posts){
                getChatInfo(userInfo.liked_posts)
            }
        } else {
            try{
                const user_response = await axios.post("https://propicks-data-api.com/get-user", {
                    gid: gid,
                })

                if(user_response){
                    setUser(user_response.data.postgres_response)
                    sessionStorage.setItem('accountData', JSON.stringify(user_response.data.postgres_response));

                    if(user_response.data.postgres_response.liked_posts){
                        getChatInfo(user_response.data.postgres_response.liked_posts)
                    }
                }
            } catch (err) {
                console.log({ err: err.message })
            }
        }
    });

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(user){
                getUserInfo(user.uid);
                setAuthenticated(true)
                setDisabled(false)
            } else {
                console.log("No user :(")
                getChatInfo();
            }
        })

    }, [])

    if(!dataExists){
        return(
            <div className='chat-page-display'>
            <Sidebar />
            <div className='main-content'>
                <button onClick={() => {navigate(-1)}}> Back </button>
                <p>
                    Loading...
                </p>
            </div>
        </div>
        )
    }

    return(
        <div className='chat-page-display'>
            <Sidebar />
            <div className='main-content'>
                <button onClick={() => {navigate(-1)}} style={{width: '100px'}}> Back </button>
                <div key={chat.message_id} className='chat-reply-stream-message'>
                <p className='chat-user' ><a style={{cursor: 'pointer'}} onClick={() => {openUserProfile(chat.user_gid)}}>{chat.user_name}</a> <span className='user-forum-span'> in </span><span className='forum-span' onClick={() => {openTeamFeed(chat.chat_stream_id)}}> {chat.forum_name}</span></p>
                <p className='chat-content'>{chat.message_data}</p>
                    { bettingLineExists ? (
                        <div className='team-betting-market-chat'>
                            <p className='team-betting-line-title' >{auditBetBook(bettingLine.book)}</p>
                            <p className='team-betting-line-title' >{auditBetKey(bettingLine.key)}</p>
                            <div className='team-betting-lines'>
                                <div className='specific-line-display'>
                                    <p className='team-betting-line-info' >{bettingLine.betting_line[0][0].name}</p>
                                    <p className='team-betting-line-info' >{bettingLine.betting_line[0][0].price}</p>
                                </div>
                                <div className='specific-line-display'>
                                    <p className='team-betting-line-info' >{bettingLine.betting_line[0][1].name}</p>
                                    <p className='team-betting-line-info' >{bettingLine.betting_line[0][1].price}</p>
                                </div>
                            </div>
                        </div>
                    ): ( <></> )}
                    <div className='button-div-chats' >
                        <button className="chat-button-like" disabled={disabled} onClick={() => {likeMessage(chat.message_id)}} ><img src={chat.is_liked ? heart_red : heart_white} height="20" width="20" /></button>
                        <p style={{marginTop: 6, fontSize: 17}}>{chat.like_count}</p>
                        <button className="chat-button-flag" disabled={disabled} onClick={() => {flagPost(chat.message_id)}} ><img src={flag} height="20" width="20" /></button>
                        <button className="chat-button-share" onClick={() => {shareChat(chat.message_id)}} ><img src={share} height="20" width="20" /></button>
                    </div>
                </div>
                <div className='chat-replies-stream'>
                    { repliesExist ? (<>
                        {chatReplies.map((chat) => {
                                return(
                                    <div key={chat.message_id} className='team-stream-message reply' >
                                        <p className='chat-user' ><a style={{cursor: 'pointer'}} onClick={() => {openUserProfile(chat.user_gid)}}>{chat.user_name}</a> </p>                                        
                                        <p className='chat-content' onClick={() => {}} >{chat.message_data}</p>
                                        <div className='button-div-chats' >
                                            <button className="chat-button-like" disabled={disabled} onClick={() => {likeReply(chat.message_id)}} ><img src={chat.is_liked ? heart_red : heart_white} height="20" width="20" /></button>
                                            <p style={{marginTop: 6, fontSize: 17}}>{chat.like_count}</p>
                                            <button className="chat-button-flag" disabled={disabled} onClick={() => {flagPost(chat.message_id)}} ><img src={flag} height="20" width="20" /></button>
                                        </div>
                                    </div>
                                )
                            })
                        }</>) : (<></>)}
                </div>
                <div className='chat-feed-input-group'>
                    <input className='chat-input-field' type='text' onKeyDown={handleKeyDown} placeholder={placeHolder} disabled={disabled} value={userMessage} onChange={handleInputChange} ></input>
                    <button className='input-button chat-feed-button' disabled={disabled} onClick={() => {respondToMessage(chatId)}}> Post </button>
                </div>
            </div>
        </div>
    )
}