import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import "./style-pages/plans.css";
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import axios from 'axios';
import Sidebar from './components/Sidebar';

export default function Plans() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');

    const [user, setUser] = useState();
    const [basicMessage, setBasicMessage] = useState("Start Free Trial!");

    const getUserInfo = useCallback(async (gid) => {
        if (session != null) {
            const userInfo = JSON.parse(session)

            setUser(userInfo);
            setBasicMessage(handleBasicButton(userInfo.tier));
        } else {
          await axios.post("https://propicks-data-api.com/get-user", {
              gid: gid,
            })
              .then((response) => {
                setUser(response.data.postgres_response);
                setBasicMessage(handleBasicButton(response.data.postgres_response.tier));
                sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
              })
              .catch((err) => {
                console.log({ err: err });
              })
        }
    });

    const handleBasicButton = (subscription) => {
        console.log(subscription);

        if(subscription == 1){
            return "Purchased!";
        } else if (subscription == 2) {
            return "Downgrade Subscription";
        } else {
            return "Start Free Trial";
        }
    }

    const handleProButton = () => {
        /*
        if(subscription == 2){
            return "Purchased!";
        } else if (subscription == 1) {
            return "Upgrade Subscription";
        } else {
            return "Subscribe Today!";
        }
        */
       return "Try Basic Today!"
    }

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if(user){
                getUserInfo(user.uid)
            } else (
                console.log("No User :(")
            )
        })
    }, [])

    return (
        <div className='plans-content'>
            <Sidebar />
            <div className='main-plans-content'>
                <div className="pricing-content-paywall">
                    <h1 className='plans-header' >Choose your Plan</h1>
                    <div className="pricing-cards">
                        <div className="pricing-card">
                            <h2>Basic</h2>
                            <div className="price">
                                <span className="currency">$</span>95.00
                                <span className="per-month">Monthly</span>
                            </div>
                            <p>Beginner Tools</p>
                            <button onClick={() => {navigate("/pay")}}>{basicMessage}</button>
                            <ul>
                                <li>Positive EV Early Access</li>
                                <li>Arbitrage Early Access</li>
                                <li>Sportsbook Screener</li>
                                <li>Social Community Early Access</li>
                                <li>Betting Calculators</li>
                            </ul>
                        </div>
                        <div className="pricing-card">
                            <h2>Pro</h2>
                            <div className="price">
                                <span className="currency"></span>Coming 2025
                                <span className="per-month">The ProPicks Pro Plan</span>
                            </div>
                            <p>Advanced Tools</p>
                            <button onClick={() => {navigate("/pay")}}>Coming Soon!</button>
                            <ul>
                                <li>All Basic Tools</li>
                                <li>Push Notifications</li>
                                <li>Exclusive Discord Access</li>
                                <li>Positive EV</li>
                                <li>Arbitrage Betting</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}