import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import { useNavigate, createSearchParams } from "react-router-dom";
import "./style-pages/generalsocial.css";
import EnterArrow from './assets/right.svg';
import axios from 'axios';

export default function GeneralSocial() {
    const navigate = useNavigate();
    const [teamFeeds, setTeamFeeds] = useState({});

    const sports = [{
        key: 'NFL',
        title: 'NFL Football',
    }, {
        key: 'NBA',
        title: 'NBA Basketball',
    }, {
        key: 'MLB',
        title: "Major League Baseball",
    }, {
        key: 'NCAAB',
        title: 'College Basketball',
    }, {
        key: 'NCAAF',
        title: 'College Football',
    }]

    const openSportFeed = (sport_key) => {
        const newParams = createSearchParams({
            sport: sport_key
        });
        
        navigate({
            pathname: '/teams',
            search: `?${newParams.toString()}`
          });
    }

    const openTeamFeed = (chat_id) => {
        const newParams = createSearchParams({
            id: chat_id
        });
        
        navigate({
            pathname: '/teamchat',
            search: `?${newParams.toString()}`
          });
    }

    return (
        <div className="general-content">
            <Sidebar />
            <main className='general-main-content'>
                <h2 className="explore-section-title">Explore</h2>
                <div className="explore-top-section" >
                    <div className="general-forum selectable" onClick={() => {openTeamFeed("00000000-0000-0000-0000-000000000000")}}>
                        <h3>
                            General Chat
                        </h3>
                    </div>
                    <div className="fantasy selectable" onClick={() => {openTeamFeed("11111111-1111-1111-1111-111111111111")}}>
                        <h3>
                            Fantasy
                        </h3>
                    </div>
                </div>
                <h2 className="sports-section-title">All Sports Chats:</h2>
                <div className="explore-team-chats">
                    {
                        sports.map((sport) => {
                            return (
                                <div key={sport.key} className="sport-feed-module selectable" onClick={() => {openSportFeed(sport.key)}}>
                                    <h4 className="team-feed-module-title">
                                        {sport.title}
                                    </h4>
                                    <img src={EnterArrow} />
                                </div>
                            )
                    })}
                </div>
            </main>
        </div>
    )
}