// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bio-content {
    display: flex;
    height: 100vh;
}

.bio-input {
    width: 75%;
    height: auto;
    font-size: 17;
    font-family: 'Roboto', sans-serif;
}

.bio-submit {
    width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/dash-pages/style-pages/changebio.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,iCAAiC;AACrC;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".bio-content {\n    display: flex;\n    height: 100vh;\n}\n\n.bio-input {\n    width: 75%;\n    height: auto;\n    font-size: 17;\n    font-family: 'Roboto', sans-serif;\n}\n\n.bio-submit {\n    width: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
