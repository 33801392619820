import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckStatus  from './CheckStatus.js';

export default function CheckContainer() {
    const stripePromise = loadStripe("pk_test_51PYtYZEgjsKDilRyDk9LvM9amBiC0fx6Nw0Xr7jYY859rXnoFCaLk5RJ4aqTpN9AxJVCHvPhLUAjtQQtR3GqNUzr006qslpjpH")

    return (
        <div>
            <Elements
                stripe={stripePromise}
            >
                <CheckStatus />
            </Elements>
        </div>
    )
}