import React, { useEffect, useCallback, useState} from 'react';
import Sidebar from './components/Sidebar';
import './style-pages/cancel.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, getAuth } from 'firebase/auth';

export default function Cancel() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');

    const [user, setUser] = useState();
    const [dataExists, setDataExists] = useState(false);

    const getUserInfo = useCallback(async (gid) => {
        if (session != null) {
            const userInfo = JSON.parse(session)
            setUser(userInfo);
            setDataExists(true);
        } else {
          await axios.post("https://propicks-data-api.com/get-user", {
              gid: gid,
            })
              .then((response) => {
                setUser(response.data.postgres_response);
                setDataExists(true);
                sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
              })
              .catch((err) => {
                console.log({ err: err });
              })
        }
    });

    const cancelSubscription = async () => {
        const response = await axios.post("https://propicks-data-api.com/cancel", {
            customer_id: user.stripe_id,
        })

        navigate("/");
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(user){
                getUserInfo(user.uid);
            } else {
                console.log("No user :(");
            }
        })
    }, [])

    return(
        <div className='cancel-content'>
            <Sidebar />
            <div className='main-content'>
                { dataExists ? (
                <div className='cancel-container'>
                    <h3>Are you sure you want to cancel your subscription?</h3>
                    <ul>
                        <li>
                            If you cancel your subscription, you will still have access to the tools until the end of your current
                            pay period. At the end of the pay period, your access to the tools will be removed.
                        </li>
                        <li>
                            If you cancel your subscription, you will still have access to the ProPicks social media.
                        </li>
                        <li>
                            Cancelling your subscription will have no effect on your social account, and your social account will still exists
                            with all it's messages, teams, and replies.
                        </li>
                    </ul>
                    <button onClick={() => {cancelSubscription()}}>I understand, cancel my subscription.</button>
                </div>
                ) : (
                    <div>
                        <p>One moment please...</p>
                    </div>
                )}
            </div>
        </div>
    )
}