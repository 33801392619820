import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import './style-pages/toolshome.css';

export default function ToolsHome() {
    const navigate = useNavigate();
    const [expandedIndex, setExpandedIndex] = useState(null);

    const faqs = [
        {
            question: 'How does this work?',
            answer:'There are over 40 sportsbooks and they all try to offer as many bets as possible. In their rush to offer countless betting options, sportsbooks occasionally "slip-up" and set odds that aren’t quite spot-on. ProPicks scans all of these odds in real-time to find these discrepancies for you. These discrepancies create returns of 1-3% every day which compound over time, giving you a significant advantage.'
        },
        {
            question: 'What justifies the $100 subscription fee?',
            answer: "At only $3.33 per day, your Pro Picks subscription is priced to be quickly offset by the betting gains you can achieve. For example, by placing just one arbitrage bet of $100 at a 3.3% return, you've already covered your daily cost. With Pro Picks, you have access to up to 100 arbitrage opportunities daily, significantly enhancing your chances to profit beyond the subscription fee. Additionally, our Positive EV and community insights further empower you to make smarter, more profitable bets. Still unsure? Start with our two-week free trial to see the value for yourself—with no obligation to continue if it doesn’t meet your expectations."
        },
        {
            question: 'How many sportsbooks do I need?',
            answer: "The more sportsbooks you have access to, the better your chances of finding the best odds and maximizing your opportunities for arbitrage and getting favorable bets. While you can start with just one or two, we recommend using multiple sportsbooks to ensure you're always getting the best returns on your bets."
        },
        {
            question: 'How much money can I make doing this?',
            answer: "The amount you can earn using Pro Picks depends on several factors, including the frequency of your bets, the amount of capital you're willing to invest, and how effectively you use our tools to exploit arbitrage opportunities and Positive EV bets. While individual results vary, our platform is designed to help you identify the most profitable betting opportunities, maximizing your chances of higher returns."
        },
        {
            question: 'What types of sports does ProPicks cover?',
            answer: "Pro Picks offers comprehensive coverage across a wide array of sports to cater to diverse betting preferences. Our platform includes major sports such as football, basketball, baseball, hockey, and soccer, as well as tennis, golf, MMA, boxing, and more. We continuously monitor and update our offerings to include new sports and events that are popular in the betting community, ensuring you have access to the best and most profitable betting opportunities across the sports world."
        },
        {
            question: 'What if I want to cancel?',
            answer: "You can cancel your subscription at any time directly from your account dashboard. There are no long-term commitments or cancellation fees, allowing you to make decisions that best suit your needs without additional pressure. Plus, if you decide to cancel within the first two weeks, you will not be charged, thanks to our free trial offer. We strive to make your experience with Pro Picks as accommodating and risk-free as possible."
        }
    ];

    const statsData = [
        { value: "100+", label: "Satisfied Subscribers" },
        { value: "100K+", label: "Bets Analyzed Everyday" },
        { value: "40+", label: "SportsBooks Scanned" },
        { value: "$30k+", label: "Total User Profits Generated" },
        { value: "100+", label: "Avg Daily Arbitrage Bets" },
    ];

    const toggleExpand = (index) => {
        setExpandedIndex(index === expandedIndex ? null : index);
    };

    return(
        <div className='tools-home-content'>
            <Sidebar />
            <div className='main-content'>
                <div className='tools-header-div'>
                    <h1>Empower Your Betting Game with ProPicks</h1>
                    <h3>Make Informed Betting Decisions with Data Analysis</h3>
                    <button onClick={() => {navigate("/plans")}}>Try for Free!</button>
                </div>
                <div className='tools-about-us-div'>
                    <div className="about-us-content">
                        <h2>What do we do?</h2>
                        <p>
                        At Pro Picks, our mission is to empower you to consistently outsmart
                        the sportsbooks. We've designed a comprehensive suite of tools that
                        illuminate the path to winning bets with greater confidence and
                        strategy. By leveraging advanced analytics, real-time data, and a
                        supportive community, Pro Picks equips you with everything you need
                        to make informed decisions and maximize your success.
                        </p>
                    </div>
                </div>
                <div className='tools-info-div'>
                    <h1 className='info-header'>Our Tools</h1>
                    <div className='tools-module-container'>
                        <div className='tool-module'>
                            <h4 className='tool-header'>
                                Arbitrage Tool
                            </h4>
                            <p className='tool-description'>
                                Unlock the secret to risk-free wins with our Arbitrage Betting tool. By exploiting differences in odds across various sportsbooks, we provide you with opportunities to place bets on all possible outcomes of an event, ensuring a guaranteed profit regardless of the result.
                            </p>
                        </div>
                        <div className='tool-module'>
                        <h4 className='tool-header'>
                                Bet Scanner
                            </h4>
                            <p className='tool-description'>
                                Your ultimate tool for navigating the complex world of sports betting odds. Instead of manually comparing odds across different platforms, our screener aggregates and presents the most favorable odds in real-time, allowing you to place bets with the confidence of getting the best possible value.
                            </p>
                        </div>
                        <div className='tool-module'>
                        <h4 className='tool-header'>
                               Positive EV
                            </h4>
                            <p className='tool-description'>
                                Master the art of strategic betting with our Positive Expected Value (Positive EV) feature. This powerful tool sifts through the noise to identify bets where the potential payout outweighs the risk, offering you a statistical edge over time.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='tools-stats-div'>
                    <h2 className="stats-header">Experience the ProPicks Advantage</h2>
                    <div className="stats-container">
                        {statsData.map((stat, index) => (
                        <div key={index} className="stat-item">
                            <div className="stat-value">{stat.value}</div>
                            <div className="stat-label">{stat.label}</div>
                            <div className="stat-divider"></div>
                        </div>
                        ))}
                    </div>
                </div>
                <div className='tools-faq-div'>
                    <h1 className="faq-title">Frequently Asked Questions</h1>
                    <div className="faq-category">General</div>
                    {faqs.map((faq, index) => (
                        <div key={index} className="faq-item">
                        <div
                            className={`faq-question ${expandedIndex === index ? 'expanded' : ''}`}
                            onClick={() => toggleExpand(index)}
                        >
                            {faq.question}
                        </div>
                        {expandedIndex === index && <div className="faq-answer">{faq.answer}</div>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}