// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-content {
    display: flex;
    height: 100vh;
}   
  
.main-content {
    flex-grow: 1;
    padding: 40px;
    background-color: #0d1117;
    color: #c9d1d9;
    width: 83%;
    margin-left: max(17%, 230px);
}

.manage-plan-button-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    min-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/dash-pages/style-pages/manageplan.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,cAAc;IACd,UAAU;IACV,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,gBAAgB;AACpB","sourcesContent":[".manage-content {\n    display: flex;\n    height: 100vh;\n}   \n  \n.main-content {\n    flex-grow: 1;\n    padding: 40px;\n    background-color: #0d1117;\n    color: #c9d1d9;\n    width: 83%;\n    margin-left: max(17%, 230px);\n}\n\n.manage-plan-button-container {\n    display: flex;\n    flex-direction: column;\n    width: 40%;\n    min-width: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
