import React, { useEffect, useState } from 'react';
import Sidebar from './components/Sidebar';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import './style-pages/userprofile.css';
import { createSearchParams } from 'react-router-dom';

import heart_white from './assets/heart_white.svg';
import reply from './assets/reply.svg';

export default function UserProfile() {
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');
    const navigate = useNavigate();
    const [userGid, setUserGid] = useState();
    const [userInfo, setUserInfo] = useState({});
    const [userPosts, setUserPosts] = useState([]);
    const [dataExists, setDataExists] = useState(false);
    const [userPostsLength, setUserPostsLength] = useState(0);
    const [teamsFollowed, setTeamsFollowed] = useState(0);
    const [disabled, setDisabled] = useState(true);


    const getUserProfile = async () => {
        const queryParams = new URLSearchParams(window.location.search)
        const gid = queryParams.get("gid")
        setUserGid(gid);

          await axios.post("https://propicks-data-api.com/get-user-with-posts", {
              "user_gid": gid,
            })
              .then((response) => {
                setUserInfo(response.data.user_info);

                if(response.data.user_posts.post_data){
                    setUserPosts(response.data.user_posts.post_data.reverse());
                    setUserPostsLength(response.data.user_posts.post_data.length);
                }
                
                if(response.data.user_info.teams_followed) {
                    setTeamsFollowed(response.data.user_info.teams_followed.length);
                }

                setDataExists(true);
              })
              .catch((err) => {
                console.log({ err: err });
              })
    };

    const likeMessage = (message_id) => {
        console.log(`liked message: ${message_id}`)
    }

    const auditBetKey = (bet_key) => {
        if(bet_key == 'h2h') return "Moneyline"
        else if (bet_key == "spreads") return "Spreads"
        else if (bet_key == "totals") return "Totals"
    }
  
    const auditBetBook = (bet_book) => {
        if(bet_book == "fanduel") return "Fanduel"
        else if (bet_book == "draftkings") return "DraftKings"
        else if (bet_book == "mybookieag") return "MyBookieAG"
        else if (bet_book == "betrivers") return "BetRivers"
        else if (bet_book == "betmgm") return "BetMGM"
        else if (bet_book == "bovada") return "Bovada"
        else if (bet_book == "lowvig") return "LowVig"
        else if (bet_book == "betus") return "BetUS"
        else return bet_book;
    }

    const openChat = (chat_id) => {
        const newParams = createSearchParams({
            id: chat_id
        });
        
        navigate({
            pathname: '/chat',
            search: `?${newParams.toString()}`
          });
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setDisabled(false);
        })
        getUserProfile();
    }, [])

    if(!dataExists){
        <div className='user-profile-content'>
            <Sidebar />
            <div className='main-content'>
                <p> Loading... </p>
            </div>
        </div>
    }

    if(dataExists && userPostsLength === 0){
        <div className='user-profile-content'>
            <Sidebar />
            <div className='main-content'>
                <button style={{height: '30px', width: '100px', marginBottom: '25px', marginTop: '-15px', padding: '5px'}} onClick={() => {navigate(-1)}}>{"< Back"}</button>
                <div className='user-attributes-section'>
                    <h3>{userInfo.name}</h3>
                </div>
                <div className='user-posts-section'>
                    <p> {userInfo.name}'s Posts: </p>
                    <p> User has no posts... </p>
                </div>
            </div>
        </div>
    }

    return (
        <div className='user-profile-content'>
            <Sidebar />
            <div className='main-content'>
                <button style={{height: '30px', width: '100px', marginBottom: '25px', marginTop: '-15px', padding: '5px'}} onClick={() => {navigate(-1)}}>{"< Back"}</button>
                <div className='profile-header-div'>
                    <h2> {userInfo.name} </h2>
                    <div className='counter-div'>
                        <h3> {userPostsLength} </h3>
                        <p> Posts </p>
                    </div>
                    <div className='counter-div'>
                        <h3> {teamsFollowed} </h3>
                        <p> Teams </p>
                    </div>
                    <button>Follow</button>
                </div>
                <div className='user-posts-section'>
                    {
                        userPosts.map((chat) => {
                            let betting_line_exists = false;
                            let betting_line;
                            console.log(chat)

                            if(chat[6]){
                                if(chat[6] != '{}'){
                                    betting_line = JSON.parse(chat[6]);
                                    betting_line_exists = true;
                                }
                            }

                            return(
                                <div key={chat[0]} className='team-stream-message' >
                                    <p className='chat-user' ><a style={{cursor: 'pointer'}} >{chat[1]}</a> {chat[8] ? <span style={{textDecoration: 'none', color: 'grey'}}> [Reply]</span> : <></>} </p>                                        
                                    <p className='chat-content' onClick={() => {openChat(chat[0])}} >{chat[3]}</p>
                                    { betting_line_exists ? (
                                          <div className='team-betting-market-chat'>
                                              <p className='team-betting-line-title' >{auditBetBook(betting_line.book)}</p>
                                              <p className='team-betting-line-title' >{auditBetKey(betting_line.key)}</p>
                                              <div className='team-betting-lines'>
                                                <div className='specific-line-display'>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][0].name}</p>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][0].price}</p>
                                                </div>
                                                <div className='specific-line-display'>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][1].name}</p>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][1].price}</p>
                                                </div>
                                              </div>
                                          </div>
                                        ): ( <></> )}
                                    <div className='button-div-chats' >
                                        <button className="chat-button-like" disabled={disabled} onClick={() => {likeMessage(chat[0])}} ><img src={heart_white} height="20" width="20" /></button>
                                        <p style={{marginTop: 6, fontSize: 17}}>{chat[11]}</p>
                                        <button className="chat-button-reply" disabled={disabled} onClick={() => {openChat(chat[0])}} ><img src={reply} height="20" width="20" /></button>
                                        <p style={{marginTop: 6, fontSize: 17}}>{chat[14]}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}