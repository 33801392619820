import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './components/Sidebar';
import './style-pages/teamschatfeed.css';
import { v4 as uuidv4 } from 'uuid';
import { onAuthStateChanged, getAuth } from 'firebase/auth';

import heart_white from './assets/heart_white.svg';
import heart_red from './assets/heart_red.svg';
import reply from './assets/reply.svg';

export default function TeamChatFeed() {
    const navigate = useNavigate();
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [teamFeedInfo, setTeamFeedInfo] = useState();
    const [teamBettingLines, setTeamBettingLines] = useState([]);
    const [teamLinesExist, setTeamLinesExist] = useState(false);
    const [teamMessages, setTeamMessages] = useState([]);
    const [dataExists, setExists] = useState(false);
    const [chatStreamId, setChatStreamId] = useState();
    const [userMessage, setUserMessage] = useState();
    const [authenticated, setAuthentication] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [placeHolder, setPlaceHolder] = useState("Sign In to Post!");
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');
    const [user, setUser] = useState({});
    const [following, setFollowing] = useState(false);
    const [userSelectedMarket, setUserSelectedMarket] = useState()
    const [showBets, setShowBets] = useState(true)

    const handleInputChange = (event) => {
        setUserMessage(event.target.value);
    };

    const getTeamFeedInfo = async () => {
        const queryParams = new URLSearchParams(window.location.search)
        const id = queryParams.get("id")
        setChatStreamId(id);

        try{
            const response = await axios.post("https://propicks-social-api.com/get-team-feed", {
                team_id: id,
            })
    
            setTeamFeedInfo(response.data.data.team_data);
    
            if(response.data.data.bet_data && response.data.data.bet_data != '[]'){
                console.log("Setting lines to: ", response.data.data.bet_data)
                setTeamBettingLines(response.data.data.bet_data);
                setTeamLinesExist(true)
            }
    
            if(response.data.data.chat_data && response.data.data.chat_data != '[]'){
                setTeamMessages(response.data.data.chat_data.reverse())
            }
            setExists(true)
        } catch (err) {
            console.log({ err: err.message })
        }
        
        
    }

    const getUserInfo = useCallback(async (gid) => {
        if (session != null) {
            const userInfo = JSON.parse(session)
            const queryParams = new URLSearchParams(window.location.search)
            const id = queryParams.get("id")

            setUser(userInfo);

            if(userInfo.teams_followed){
                if(userInfo.teams_followed.includes(id)){
                    setFollowing(true);
                    console.log("following team")
                }
            }
        } else {
          await axios.post("https://propicks-data-api.com/get-user", {
              gid: gid,
            })
              .then((response) => {
                setUser(response.data.postgres_response);
                sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
              })
              .catch((err) => {
                console.log({ err: err });
              })
        }
    });

    const updateUserInfo = useCallback(async (gid) => {
        await axios.post("https://propicks-data-api.com/get-user", {
            gid: gid,
          })
            .then((response) => {
              setUser(response.data.postgres_response);
              sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
            })
            .catch((err) => {
              console.log({ err: err });
            })
    })

    const postMessage = async () => {
        const date = new Date();
        const uuid = uuidv4();

        let chat_array;
        const chat = {
            message_id: uuid,
            user_name: user.name,
            user_gid: user.gid,
            message_data: userMessage,
            post_date: date,
            chat_stream_id: chatStreamId,
            forum_name: teamFeedInfo[0],
            betting_line: null,
            is_liked: false,
            like_count: 0,
            comment_count: 0,
            is_reply: false,
        }

        if(userSelectedMarket){
            chat.betting_line = userSelectedMarket
            chat_array = [uuid, user.name, user.gid, userMessage, date, chatStreamId, JSON.stringify(userSelectedMarket), teamFeedInfo[0][0], null, null, null, 0, null, null, 0]
        } else {
            chat_array = [uuid, user.name, user.gid, userMessage, date, chatStreamId, null, teamFeedInfo[0][0], null, null, null, 0, null, null, 0]
        }

        // Combine these two calls into one, client should not make two api calls in a single method
        await axios.post("https://propicks-social-api.com/post-team-feed-message", {
            chat: chat,
        })
            .then((response) => {
                console.log(response);
                setTeamMessages(teamMessages => [chat_array, ...teamMessages])
                setUserMessage('')
                console.log(teamMessages)
            })
            .catch((err) => {
                console.log({"message": err.message});
            })

        await axios.post("https://propicks-data-api.com/add-user-post", {
            "user_gid": user.gid,
            "message_id": uuid,
        })
            .catch((err) => {
                console.log({ "message": err.message });
            })

        forceUpdate();
    }

    const likeMessage = (message_id) => {
        console.log(`User: ${user.gid} liked message: ${message_id}`)
    }

    const followTeam = async () => {
        await axios.post("https://propicks-data-api.com/follow-team", {
            team_id: chatStreamId,
            user_gid: user.gid,
        })
            .then((result) => {
                console.log(result);
                updateUserInfo(user.gid);
                setFollowing(true);
                forceUpdate()
            })
            .catch((err) => {
                console.log({'message': err.message})
            })
    }

    const unfollowTeam = async () => {
        await axios.post("https://propicks-data-api.com/unfollow-team", {
            team_id: chatStreamId,
            user_gid: user.gid,
        })
            .then((result) => {
                console.log(result);
                updateUserInfo(user.gid);
                setFollowing(false);
                forceUpdate()
            })
            .catch((err) => {
                console.log({ 'message': err.message });
            })
    }

    const openUserProfile = (user_gid) => {
        const newParams = createSearchParams({
            gid: user_gid
        });
        
        navigate({
            pathname: '/userProfile',
            search: `?${newParams.toString()}`
          });
    }

    const openChat = (chat_id) => {
        const newParams = createSearchParams({
            id: chat_id
        });
        
        navigate({
            pathname: '/chat',
            search: `?${newParams.toString()}`
          });
    }

    const selectMarket = (bet) => {
        if(userSelectedMarket === bet){
            setUserSelectedMarket(null)
        } else setUserSelectedMarket(bet);
    }

    const auditBetKey = (bet_key) => {
        if(bet_key == 'h2h') return "Moneyline"
        else if (bet_key == "spreads") return "Spread"
        else if (bet_key == "totals") return "Totals"
    }
  
    const auditBetBook = (bet_book) => {
        if(bet_book == "fanduel") return "Fanduel"
        else if (bet_book == "draftkings") return "DraftKings"
        else if (bet_book == "mybookieag") return "MyBookieAG"
        else if (bet_book == "betrivers") return "BetRivers"
        else if (bet_book == "betmgm") return "BetMGM"
        else if (bet_book == "bovada") return "Bovada"
        else if (bet_book == "lowvig") return "LowVig"
        else if (bet_book == "betus") return "BetUS"
        else return bet_book;
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          postMessage();
        }
    };

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(user){
              getUserInfo(user.uid);
              setAuthentication(true)
              setDisabled(false)
              setPlaceHolder("Enter message...")
            } else {
              console.log("no user :(");
            }
          })
        getTeamFeedInfo();
    }, [])

    if(!dataExists){
        return(
            <div className='teams-feed-content'>
                <Sidebar />
                <div>
                    <p>
                        Loading...
                    </p>
                </div>
            </div>
        )
    }

    return(
        <div className='teams-feed-content'>
            <Sidebar />
            <div className='main-content-team-chat-feed'>
                <div className='team-top-button-container'>
                    <button style={{height: '30px', width: '100px', marginBottom: '25px', padding: '5px'}} onClick={() => {navigate(-1)}}>{"< Back"}</button>
                    { teamLinesExist ? (<button style={{height: '30px', width: '100px', marginBottom: '25px', padding: '5px'}} onClick={() => {setShowBets(!showBets)}}> {showBets ? "Hide Bets" : "Show Bets"}</button>) : (<></>)}
                    {following ? (
                        <>
                            <button style={{height: '30px', width: '125px', marginBottom: '25px', padding: '5px'}} onClick={() => {unfollowTeam()}}>Unfollow Team</button>
                        </>
                    ) : (
                        <>
                            <button style={{height: '30px', width: '125px', marginBottom: '25px', padding: '5px'}} onClick={() => {followTeam()}}>Follow Team</button>
                        </>
                    )}
                </div>
                <div className='team-feed-title-div' >
                    <div className='team-info-div' >
                        <h3 className='team-title' >{teamFeedInfo[0]}</h3>
                        <h4 className='next-game-text' >{teamFeedInfo[2]}</h4>
                    </div>
                    <div className='team-odds-div'>
                        {teamLinesExist & showBets ? (
                            <div className='team-odds-map'>
                                {
                                    teamBettingLines.map((bet) => {
                                        const isSelected = userSelectedMarket === bet;

                                        return(
                                            <div className={`team-betting-market ${isSelected ? 'selected' : ''}`} onClick={() => selectMarket(bet)}>
                                                <p className='team-betting-line-title' >{auditBetBook(bet.book)}</p>
                                                <p className='team-betting-line-title' >{auditBetKey(bet.key)}</p>
                                                <div className='team-betting-lines'>
                                                    <div className='specific-line-display'>
                                                        <p className='team-betting-line-info' >{bet.betting_line[0][0].name}</p>
                                                        <p className='team-betting-line-info' >{bet.betting_line[0][0].price}</p>
                                                    </div>
                                                    <div className='specific-line-display'>
                                                        <p className='team-betting-line-info' >{bet.betting_line[0][1].name}</p>
                                                        <p className='team-betting-line-info' >{bet.betting_line[0][1].price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : (
                            <></>
                        )}
                        
                    </div>
                </div>
                <div className='chat-feed'>
                    {
                        teamMessages.map((chat) => {
                            let betting_line_exists = false;
                            let betting_line;

                            if(chat[6]){
                                if(chat[6] != '{}'){
                                    betting_line = JSON.parse(chat[6]);
                                    betting_line_exists = true;
                                }
                            }

                            return(
                                <div key={chat[0]} className='team-stream-message' >
                                    <p className='chat-user' ><a style={{cursor: 'pointer'}} onClick={() => {openUserProfile(chat[2])}}>{chat[1]}</a> </p>                                        
                                    <p className='chat-content' onClick={() => {openChat(chat[0])}} >{chat[3]}</p>
                                    { betting_line_exists ? (
                                          <div className='team-betting-market-chat'>
                                              <p className='team-betting-line-title' >{auditBetBook(betting_line.book)}</p>
                                              <p className='team-betting-line-title' >{auditBetKey(betting_line.key)}</p>
                                              <div className='team-betting-lines'>
                                                <div className='specific-line-display'>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][0].name}</p>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][0].price}</p>
                                                </div>
                                                <div className='specific-line-display'>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][1].name}</p>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][1].price}</p>
                                                </div>
                                              </div>
                                          </div>
                                        ): ( <></> )}
                                    <div className='button-div-chats' >
                                        <button className="chat-button-like" disabled={disabled} onClick={() => {likeMessage(chat[0])}} ><img src={heart_white} height="20" width="20" /></button>
                                        <p style={{marginTop: 6, fontSize: 17}}>{chat[11]}</p>
                                        <button className="chat-button-reply" disabled={disabled} onClick={() => {openChat(chat[0])}} ><img src={reply} height="20" width="20" /></button>
                                        <p style={{marginTop: 6, fontSize: 17}}>{chat[14]}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='team-feed-input-group'>
                    <input className='chat-input-field' type='text' onKeyDown={handleKeyDown} placeholder={placeHolder} disabled={disabled} value={userMessage} onChange={handleInputChange} ></input>
                    <button className='input-button team-feed-button' disabled={disabled} onClick={() => {postMessage()}}> Post </button>
                </div>
            </div>
        </div>
    )
}