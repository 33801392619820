import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import './style-pages/groups.css';

export default function Groups() {
    const navigation = useNavigate();

    return(
        <div className='groups-content'>
            <Sidebar />
            <div className='main-content'>
                <h3>
                    Welcome to Propicks Groups!
                </h3>
                <p>
                    Groups allow you to discuss sports, betting odds, and teams with friends! 
                </p>
            </div>
        </div>
    )
}