import React, { useState, useEffect, useCallback, useReducer, useRef } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import './style-pages/dashboard.css';
import Sidebar from './components/Sidebar';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import debounce from 'lodash.debounce';

import heart_white from './assets/heart_white.svg';
import heart_red from './assets/heart_light_red_thick.svg';
import reply from './assets/reply.svg';
import share from './assets/share.svg';

export default function MainDashboard() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const feedRef = useRef(null);

    const [disabled, setDisabled] = useState(true);
    const [authenticated, setAuthentication] = useState(false);
    const [user, setUser] = useState({});
    const [chats, setChats] = useState([]);
    const [dataExists, setDataExists] = useState(false);
    const [userMessage, setUserMessage] = useState();
    const [placeHolder, setPlaceHolder] = useState("Sign In to Post!");
    const [userTeams, setUserTeams] = useState([]);
    const [arbCount, setArbCount] = useState(0);
    const [pevCount, setPevCount] = useState(0);
    const [callLoading, setCallLoading] = useState(false);
    const [lastDate, setLastDate] = useState();
    const [recentLastDate, setRecentLastDate] = useState();
    const [messageTooLong, setMessageTooLong] = useState(false);

    // IMPORTANT UPDATE - Need to add reply counter to chats (only the main chat) so it can be displayed on the main page.
    // Also need to add flagging to posts, will just mark it in the database for now but this should help to find offensive posts

    // Some other crucial updates for later down the line:
    // - Add more bet types, and possibly an extra page just for choosing bets, so users have more options to discuss
    // - Add a parlay builder so that users can post their parleys and talk about them
    // - Add groups such that users can create and interact in their own groups
    // - Build out the tools home page to better advertise the tools
    // - Finish settings page and add the required capabilities so all buttons are working
    // - Finish the getting started page so that we have the links to the books (affiliate links)
    // - The backend server for getting and updating bets needs to be run more often, figure that out
    // - Clean up the code on the frontend for a first version release
    // - Add more search terms to the bet scanner as well as adding more searching to all tools
    // - Looks for ways to make the servers, database calls, and client calls more efficient to save money
    // - need to expand likes to team feeds
    // - need to expand comment counts to team feeds (and maybe chats)
    // - Limit text lengths on posts, replies, bios, team chats, and group messages.
    // - The bet scanner tool needs to be paywalled
    // - Expand functionality on frontend so users can follow/unfollow other users

    // Errs:
    // - On the (live) chat page, the like counter doesn't increment/decrement
    // - The follow button looks like shit... just in general
    // - The team feed chat does not correctly handle likes...

    const loadDashboard = useCallback(async (gid) => {
      try{
        const response = await axios.post("https://propicks-data-api.com/load-dashboard", {
          gid: gid
        })

        let chats = response.data.chats;

        if(response.data.user && response.data.user.liked_posts){
          chats.map((chat) => {
            if(response.data.user.liked_posts.includes(chat.message_id)){
              chat.is_liked = true;
            }
          })
        }
        
        setChats(chats);

        setLastDate(chats[chats.length - 1].post_date);
        setArbCount(response.data.counts.arb_count.count);
        setPevCount(response.data.counts.pev_count.count);
        setDataExists(true);

        if(response.data.user != undefined || response.data.user != null){
          setUser(response.data.user);
          sessionStorage.setItem('accountData', JSON.stringify(response.data.user));
        }
        setUserTeams(response.data.teams);
        setAuthentication(true)
      } catch (err) {
        console.log("An error has occurred when loading dashbaord.")
        console.log({ message: err.message })
      }
    }, []);

    const getChatInfo = useCallback(async () => {
      try {
        const result = await axios.get("https://propicks-data-api.com/recent-posts");
        console.log(result.data.chats);
        let reverse_chats = result.data.chats.reverse();
        setChats(reverse_chats);
        setDataExists(true);
      } catch (err) {
        console.log({ message: err.message });
      }
    }, []);
    
    const handleInputChange = (event) => {
      if(event.target.value.length > 300){
        setMessageTooLong(true);
        setUserMessage(event.target.value);
      } else {
        setUserMessage(event.target.value);
        setMessageTooLong(false);
      }
    };

    const postMessage = async () => {
      if(messageTooLong){
        alert("Message is too long! Try making it a bit shorter...")
        return;
      }
      
      const date = new Date();
      const uuid = uuidv4();

      const general_id = "00000000-0000-0000-0000-000000000000";
      const general_name = "General";

      const chat = {
          message_id: uuid,
          user_name: user.name,
          user_gid: user.gid,
          message_data: userMessage,
          post_date: date,
          chat_stream_id: general_id,
          forum_name: general_name,
          betting_line: null,
          is_liked: false,
          comment_count: 0,
          like_count: 0,
          is_reply: false,
      }

      // Combine these two calls into one, client should not make two api calls in a single method
      await axios.post("https://propicks-social-api.com/post-team-feed-message", {
          chat: chat,
      })
          .then((response) => {
              setChats(chats => [chat, ...chats]);
              setUserMessage('')
          })
          .catch((err) => {
              console.log({"message": err.message});
          })

      await axios.post("https://propicks-data-api.com/add-user-post", {
          "user_gid": user.gid,
          "message_id": uuid,
      })
          .catch((err) => {
              console.log({ "message": err.message });
          })

      forceUpdate();
    };

    const openUserProfile = (user_gid) => {
      const newParams = createSearchParams({
          gid: user_gid
      });
      
      navigate({
          pathname: '/userProfile',
          search: `?${newParams.toString()}`
        });
    };

    const getUserInfo = useCallback(async (gid) => {
      if (session != null) {
        setUser(JSON.parse(session));
      } else {
        console.log("session is null on main")
        await axios.post("https://propicks-data-api.com/get-user", {
            gid: gid,
          })
            .then((response) => {
              setUser(response.data.postgres_response);
              sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
            })
            .catch((err) => {
              console.log({ err: err });
            })
      }
    });

    const likeMessage = async (message_id) => {
      try {
        const response = await axios.post("https://propicks-data-api.com/like-post", {
          post_id: message_id,
          user_id: user.gid,
        })

        if(response.status == 200){
          handleLike(message_id)
        } else {
          console.log("There was an error...")
        }
      } catch (err) {
        console.log({ err: err.message })
      }
    };

    const openTeamFeed = (chat_id) => {
      const newParams = createSearchParams({
          id: chat_id
      });
      
      navigate({
          pathname: '/teamchat',
          search: `?${newParams.toString()}`
        });
    };

    const openChat = (chat_id) => {
      const newParams = createSearchParams({
          id: chat_id
      });
      
      navigate({
          pathname: '/chat',
          search: `?${newParams.toString()}`
        });
    };

    const auditBetKey = (bet_key) => {
      if(bet_key == 'h2h') return "Moneyline"
      else if (bet_key == "spreads") return "Spread"
      else if (bet_key == "totals") return "Totals"
    };

    const auditBetBook = (bet_book) => {
      if(bet_book == "fanduel") return "Fanduel"
      else if (bet_book == "draftkings") return "DraftKings"
      else if (bet_book == "mybookieag") return "MyBookieAG"
      else if (bet_book == "betrivers") return "BetRivers"
      else if (bet_book == "betmgm") return "BetMGM"
      else if (bet_book == "bovada") return "Bovada"
      else if (bet_book == "lowvig") return "LowVig"
      else if (bet_book == "betus") return "BetUS"
      else return bet_book;
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        postMessage();
      }
    };

    const handleKeyDownNoAuth = (event) => {
      if(event.key === 'Enter'){
        navigate('/signin')
      }
    };

    const shareChat = (message_id) => {
      const link = "https://propicks.io/chat?id=" + message_id;
      navigator.clipboard.writeText(link)
    }

    const loadAdditionalChats = useCallback(async () => {
      if (callLoading) return;
      if (recentLastDate == lastDate) return;
      setCallLoading(true);

      try {
        const response = await axios.post("https://propicks-data-api.com/get-more-chats", {
          cutoff_date: lastDate,
        })

        setRecentLastDate(lastDate);
        const new_chats = response.data.postgres_response

        new_chats.map((chat) => {
          if(checkIfLiked(chat.message_id)){
            chat.is_liked = true;
          }
        })

        if(response.status == 200){
          setChats(chats => [...chats, ...new_chats])
          setLastDate(response.data.postgres_response[response.data.postgres_response.length - 1].post_date)
          setCallLoading(false);
        } else {
          console.log("There was an error loading more posts...")
          setCallLoading(false);
        }
      } catch (err) {
        console.log({ err: err.message })
        setCallLoading(false);
      }
      
    })

    const handleScroll = useCallback(
      debounce(() => {
        const feed = feedRef.current;
    
        if (feed.scrollTop + feed.clientHeight >= feed.scrollHeight - 300) {
          console.log("Bottom hit...");
          loadAdditionalChats();
        }
      }, 200),
      [loadAdditionalChats]
    );

    const checkIfLiked = (message_id) => {
      if(user.liked_posts){
        if(user.liked_posts.includes(message_id)){
          return true;
        } else {
          return false;
        }
      }
    }

    const handleLike = (message_id) => {
      setChats((chats) =>
          chats.map((chat) => {
              if (chat.message_id === message_id) {
                const isLiked = !chat.is_liked;
                return { ...chat, is_liked: isLiked, like_count: chat.like_count + (isLiked ? 1 : -1) };
              }
              return chat;
          })
      );
      forceUpdate()
    };

    useEffect(() => {
      const fetchData = async () => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            loadDashboard(user.uid);
            setDisabled(false);
            setPlaceHolder("Join the conversation!")
          } else {
            console.log("no user :(");
            loadDashboard();
          }
        });
      };
    
      fetchData();
    }, []);

    useEffect(() => {
      const feed = feedRef.current;
      if (!feed) return;

      feed.addEventListener('scroll', handleScroll);
      return () => feed.removeEventListener('scroll', handleScroll);
    }, [chats]);

    if(!dataExists && !authenticated){
      return (
        <div className='dashboard'>
          <Sidebar />
          <div className='main-dashboard-content'>
            <p> loading... </p>
          </div>
        </div>
      )
    }

    if(authenticated && dataExists){
        return (
            <div className="dashboard">
              <Sidebar />
              <main className="main-dashboard-content">
                <div className="modules">
                  <div className="module left">
                    <div className="chat-stream-module" >
                          <div className='main-chat-stream' ref={feedRef} >
                            {chats.length > 0 ? (<>
                              {
                                chats.map((chat) => {
                                  let betting_line;
                                  let betting_line_exists = false;

                                  if(chat.betting_line){
                                    if(chat.betting_line != "{}"){
                                      betting_line = JSON.parse(chat.betting_line)
                                      betting_line_exists = true;
                                    }
                                  }

                                  return(
                                    <div key={chat.message_id} className='dashboard-team-feed-message'>
                                        <p className='dashboard-chat-user' ><a style={{cursor: 'pointer'}} onClick={() => {openUserProfile(chat.user_gid)}}>{chat.user_name}</a> <span className='user-forum-span'> in </span><span className='forum-span' onClick={() => {openTeamFeed(chat.chat_stream_id)}}> {chat.forum_name}</span></p>
                                        <p className='dashboard-chat-content' onClick={() => {openChat(chat.message_id)}} >{chat.message_data}</p>
                                        { betting_line_exists ? (
                                          <div className='dashboard-team-betting-market-chat' onClick={() => {openChat(chat.message_id)}} >
                                              <p className='team-betting-line-title' >{auditBetBook(betting_line.book)}</p>
                                              <p className='team-betting-line-title' >{auditBetKey(betting_line.key)}</p>
                                              <div className='team-betting-lines'>
                                                <div className='specific-line-display'>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][0].name}</p>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][0].price}</p>
                                                </div>
                                                <div className='specific-line-display'>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][1].name}</p>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][1].price}</p>
                                                </div>
                                              </div>
                                          </div>
                                        ): ( <></> )}
                                        <div className='button-div-chats' >
                                          <button className="chat-button-like" disabled={disabled} onClick={() => {
                                            likeMessage(chat.message_id);
                                          }} ><img src={chat.is_liked ? heart_red : heart_white} height="20" width="20" /></button>
                                          <p style={{marginTop: 6, fontSize: 17}}>{chat.like_count}</p>
                                          <button className="chat-button-reply" disabled={disabled} onClick={() => {openChat(chat.message_id)}} ><img src={reply} height="20" width="20" /></button>
                                          <p style={{marginTop: 6, fontSize: 17}}>{chat.comment_count}</p>
                                          <button className="chat-button-share" onClick={() => {shareChat(chat.message_id)}} ><img src={share} height="20" width="20" /></button>
                                        </div>
                                    </div>
                                  )
                                })
                              }
                            </>):(<>
                              <p>No Chats loaded</p>
                            </>)}
                          </div>
                          <div className='dashboard-input-group'>
                            <input className={messageTooLong ? 'dashboard-chat-input-field too-long' : "dashboard-chat-input-field"} type='text' onKeyDown={handleKeyDown} placeholder={placeHolder} disabled={disabled} value={userMessage} onChange={handleInputChange} ></input>
                            <button className='dashboard-input-button' disabled={disabled} onClick={() => {postMessage()}}> Post </button>
                          </div>
                        </div>
                  </div>
                  <div className="module right">
                    <div className="account-module">
                      <h2 className='dashboard-header'>Welcome {user.name}</h2>
                      <div className='dashboard-user-section-divider' />
                      <h4 className="dashboard-user-section-header">Your Teams:</h4>
                      {(userTeams.length != 0) ? (<>
                        {userTeams.map((team) => {
                          return(
                            <div className='team-name-display-div' onClick={() => {openTeamFeed(team.chat_stream_id)}}>
                              <p>{team.team_name}</p>
                            </div>
                          )
                        })}</>) : (
                          <>
                            <p style={{paddingLeft: 10}} >You dont follow any teams!</p>
                            <p style={{paddingLeft: 10}} >Check the <span onClick={() => {navigate('/explore')}}>Explore Page</span> to find some.</p>
                          </>
                        )
                      }
                      <h4 className="dashboard-user-section-header" >Your Bets:</h4>
                      <p className='team-name-display-div' onClick={() => {navigate('/positiveev')}} >Positive EV: {pevCount}</p>
                      <p className='team-name-display-div' onClick={() => {navigate('/arbitrage')}} >Arbitrage: {arbCount}</p>
                      <div className='dashboard-user-section-divider' />
                      <h4 className="dashboard-user-section-header" >ProPicks News:</h4>
                      <p> 
                        Welcome to ProPicks! Our app is currently in its first beta version, 
                        but we are working hard to get it into production. Some features are working well 
                        such as the chat functionality and our tools. We also have some features that are 
                        coming soon such as fantasy tools for fantasy lineups, the ability to like chats, 
                        and profile notifications! Create an account and check back often to see more updates 
                        and tools!
                      </p>
                      <div className='dashboard-user-section-divider' />
                      <h4 className="dashboard-user-section-header" >Learn More:</h4>
                      <button className='signin-dash-button' onClick={() => {navigate('/gettingstarted')}}>Getting Started</button>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          );
    }

    if(!authenticated && dataExists){
      return(
        <div className="dashboard">
            <Sidebar />
            <main className="main-dashboard-content">
                <div className="modules">
                    <div className="module left">
                        <div className="chat-stream-module">
                          <div className='main-chat-stream' ref={feedRef} >
                            {chats.length > 0 ? (<>
                              {
                                chats.map((chat) => {
                                  let betting_line;
                                  let betting_line_exists = false;

                                  if(chat.betting_line){
                                    if(chat.betting_line != "{}"){
                                      betting_line = JSON.parse(chat.betting_line)
                                      betting_line_exists = true;
                                    }
                                  }

                                  return(
                                    <div key={chat.message_id} className='dashboard-team-feed-message'>
                                        <p className='chat-user' ><a style={{cursor: 'pointer'}} onClick={() => {openUserProfile(chat.user_gid)}}>{chat.user_name}</a> <span className='user-forum-span'> in </span><span className='forum-span' onClick={() => {openTeamFeed(chat.chat_stream_id)}}> {chat.forum_name}</span></p>
                                        <p className='chat-content' onClick={() => {openChat(chat.message_id)}} >{chat.message_data}</p>
                                        { betting_line_exists ? (
                                          <div className='dashboard-team-betting-market-chat' onClick={() => {openChat(chat.message_id)}} >
                                              <p className='team-betting-line-title' >{auditBetBook(betting_line.book)}</p>
                                              <p className='team-betting-line-title' >{auditBetKey(betting_line.key)}</p>
                                              <div className='team-betting-lines'>
                                                <div className='specific-line-display'>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][0].name}</p>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][0].price}</p>
                                                </div>
                                                <div className='specific-line-display'>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][1].name}</p>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][1].price}</p>
                                                </div>
                                              </div>
                                          </div>
                                        ): ( <></> )}
                                        <div className='button-div-chats' >
                                          <button className="chat-button-like" disabled={disabled} onClick={() => {likeMessage(chat.message_id)}} ><img src={heart_white} height="20" width="20" /></button>
                                          <button className="chat-button-reply" disabled={disabled} onClick={() => {openChat(chat.message_id)}} ><img src={reply} height="20" width="20" /></button>
                                        </div>
                                    </div>
                                  )
                                })
                              }
                            </>):(<>
                              <p>No Chats loaded</p>
                            </>)}
                          </div>
                          <div className='dashboard-input-group'>
                            <input className="dashboard-chat-input-field" type='text' onKeyDown={handleKeyDownNoAuth} placeholder={placeHolder} value={userMessage} onChange={handleInputChange} ></input>
                            <button className='dashboard-input-button' onClick={() => {navigate('/signin')}}> Post </button>
                          </div>
                        </div>
                    </div>
                    <div className="module right">
                        <div className='welcome-module'>
                            <h2 className='dashboard-header'>Welcome to Propicks</h2>
                            <button className='signin-dash-button' onClick={() => {navigate('/signin')}}>Sign In</button>
                            <button className='signin-dash-button' onClick={() => {navigate('/gettingstarted')}}>Getting Started</button>
                            <h4 className="dashboard-user-section-header" >ProPicks News:</h4>
                            <p> 
                              Welcome to ProPicks! Our app is currently in its first beta version, 
                              but we are working hard to get it into production. Some features are working well 
                              such as the chat functionality and our tools. We also have some features that are 
                              coming soon such as fantasy tools for fantasy lineups, the ability to like chats, 
                              and profile notifications! Create an account and check back often to see more updates 
                              and tools!
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
      )
    }
}

/*
Unused Code: 

<div className="news-module">
    <h3 className='news-header'>Sports News</h3>
</div>
<div className='placeholder-module-1'>
  <h3 className='placeholder-header-1'>New module coming soon...</h3>
</div>
<div className='placeholder-module-2'>
  <h3 className='placeholder-header-2'>New module coming soon...</h3>
</div>

 <img src={heart} style={{height: 20, marginTop: 2.5,}}/>
*/