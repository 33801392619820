// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-content {
    display: flex;
    height: 100vh;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 40px;
    background-color: #0d1117;
    color: #c9d1d9;
    width: 83%;
    margin-left: max(17%, 230px);
  }
  
  .main-content h2 {
    color: #58a6ff;
    margin-bottom: 20px;
  }

  .button-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .button-container button {
    padding: 10px 20px;
    background-color: #21262d;
    color: #c9d1d9;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button-container button:hover {
    background-color: #30363d;
  }

  .option-container {
    display: flex;
    flex-direction: row;
    justify-items: center;
  }

  .option-button {
    height: 45px;
    width: 100px;
    margin-left: 15px;
    margin-top: 0px;
    background-color: transparent;
    color: white;
    border-radius: 8px;
    border: 1px solid grey;
  }

  .long {
    width: 150px;
  }

  .danger:hover {
    background-color: red;
  }

  .option-header {
    width: 50%;
    padding-bottom: 5px;
    border-bottom: 1px solid grey;
  }`, "",{"version":3,"sources":["webpack://./src/dash-pages/style-pages/settings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,cAAc;IACd,UAAU;IACV,4BAA4B;EAC9B;;EAEA;IACE,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,SAAS;IACT,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,qBAAqB;EACvB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,UAAU;IACV,mBAAmB;IACnB,6BAA6B;EAC/B","sourcesContent":[".settings-content {\n    display: flex;\n    height: 100vh;\n  }\n  \n  .main-content {\n    flex-grow: 1;\n    padding: 40px;\n    background-color: #0d1117;\n    color: #c9d1d9;\n    width: 83%;\n    margin-left: max(17%, 230px);\n  }\n  \n  .main-content h2 {\n    color: #58a6ff;\n    margin-bottom: 20px;\n  }\n\n  .button-container {\n    display: flex;\n    gap: 10px;\n    margin-bottom: 20px;\n  }\n  \n  .button-container button {\n    padding: 10px 20px;\n    background-color: #21262d;\n    color: #c9d1d9;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .button-container button:hover {\n    background-color: #30363d;\n  }\n\n  .option-container {\n    display: flex;\n    flex-direction: row;\n    justify-items: center;\n  }\n\n  .option-button {\n    height: 45px;\n    width: 100px;\n    margin-left: 15px;\n    margin-top: 0px;\n    background-color: transparent;\n    color: white;\n    border-radius: 8px;\n    border: 1px solid grey;\n  }\n\n  .long {\n    width: 150px;\n  }\n\n  .danger:hover {\n    background-color: red;\n  }\n\n  .option-header {\n    width: 50%;\n    padding-bottom: 5px;\n    border-bottom: 1px solid grey;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
