import React, { useState, useCallback, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import './style-pages/manageplan.css';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import axios from 'axios';

export default function ManagePlan() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');

    const [user, setUser] = useState();
    const [userExists, setUserExists] = useState(false);
    const [subscriptionExists, setSubscriptionExists] = useState(false);

    const getUserInfo = useCallback(async (gid) => {
        if (session != null) {
            const userInfo = JSON.parse(session)
            setUser(userInfo);
            setUserExists(true);
            checkForSubscription(userInfo.tier)
        } else {
          await axios.post("https://propicks-data-api.com/get-user", {
              gid: gid,
            })
              .then((response) => {
                setUser(response.data.postgres_response);
                setUserExists(true);
                checkForSubscription(response.data.postgres_response.tier);
                sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
              })
              .catch((err) => {
                console.log({ err: err });
              })
        }
    });

    const checkForSubscription = useCallback((tier) => {
        console.log("tier found: ", tier)
        if(Number(tier) == 0){
            console.log(tier)
            setSubscriptionExists(false);
        } else {
            setSubscriptionExists(true);
        }
    })

    const auditSubscriptionName = (tier) => {
        if(tier == 2){
            return "Premium Plan";
        } else if (tier == 1){
            return "Basic Plan";
        } else {
            return "Free Plan";
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(user){
                getUserInfo(user.uid)
            } else {
                console.log("No User :(")
            }
        })
    },[])

    if(!userExists){
        return(
            <div className='manage-content'>
                <Sidebar />
                <div className='main-content'>
                    <div className="login-cta-div" >
                        <h3>Don't have an account? Create one now!</h3>
                        <button onClick={() => {navigate("/signin")}}>Sign In</button>
                    </div>
                </div>
            </div>
        )
    }

    if(!subscriptionExists){
        return(
            <div className='manage-content'>
                <Sidebar />
                <div className='main-content'>
                    <h2>Manage Plan: </h2>
                    <p>Plan: Free Plan</p>
                    <h3>Looks like you're on the free plan... Find a better plan today!</h3>
                    <button onClick={() => {navigate("/plans")}}>Plans</button>
                </div>
            </div>
        )
    }

    if(subscriptionExists){
        return(
            <div className='manage-content'>
                <Sidebar />
                <div className='main-content'>
                    <h3>Manage Plan: </h3>
                    <p>Plan: {auditSubscriptionName(user.tier)}</p>
                    <p>Next Payment Date: </p>
                    <p>Payment Method: </p>
                    <p>Discount: Not Applicable</p>
                    <div className='manage-plan-button-container'>
                        <button onClick={() => {navigate("/plans")}}>Change Plan</button>
                        <button onClick={() => {navigate("/cancel")}}>Cancel Plan</button>
                    </div>
                </div>
            </div>
        )
    }
}