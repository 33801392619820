import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./style-pages/paymentpage.css";
import "./PaymentContainer.js";
import StripeContainer from './StripeContainer.js';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import PaymentForm from './PaymentForm.js';
import axios from 'axios'

export default function PaymentPage() {
    const navigate = useNavigate();
    const auth = getAuth();

    const [isAuthenticated, setAuthentication] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(user){
                setAuthentication(true);
            };
        })

    })

    return (
        <div id="personalCheckoutDiv">
            { isAuthenticated ? (
                <div style={{marginTop: 20}}>
                    <StripeContainer type="0" trial={false}/>
                </div>
            ) : (
                <div>
                    <p>You are not signed in!</p>
                    <button onClick={() => {navigate("/signin")}}>Sign In Now!</button>
                </div>
            )}
        </div>
    )
}
