import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from './components/Sidebar';
import './style-pages/betscanner.css';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';

export default function BetScanner() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');

    const [authenticated, setAuthentication] = useState(false);
    const [user, setUser] = useState({});
    const [subscribed, setSubscribed] = useState(true);
    const [betData, setBetData] = useState([]);
    const [dataExists, setDataExists] = useState(false);
    const [searchEngaged, setSearchEngaged] = useState(false);
    const [keySearchOpen, setKeySearchOpen] = useState(false);

    const propogateBetData = async () => {
      try {
        const response = await axios.get("https://propicks-data-api.com/scanner-all")

        if(response){
          setBetData(response.data.events);
          setDataExists(true);
        }
      } catch (err) {
        console.log({ err: err.message })
      }
    }

    const searchByKey = async (key) => {
      try {
        const response = await axios.post("https://propicks-data-api.com/scanner-search-key", {
          bet_key: key
        })

        if(response){
          setBetData(response.data.events);
          setDataExists(true);
        }
      } catch (err) {
        console.log({ err: err.message });
      }
    }

    const auditBetKey = (bet_key) => {
      if(bet_key == 'h2h') return "Moneyline"
      else if (bet_key == "spreads") return "Spread"
      else if (bet_key == "totals") return "Totals"
    }

    const getUserInfo = useCallback(async (gid) => {
      if (session != undefined) {
        setUser(JSON.parse(session));
      } else {
        await axios.post("https://propicks-data-api.com/get-user", {
            gid: gid,
          })
            .then((response) => {
              setUser(response.data.postgres_response);
              sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
            })
            .catch((err) => {
              console.log({ err: err });
            })
      }
    });

    const checkSubscription = () => {
      if(user.tier == 1 || user.tier == 2){
          setSubscribed(true);
      }
    }

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if(user){
          setAuthentication(true);
          getUserInfo(user.uid);
        } else {
          console.log("no user :(");
        }
      })

    }, [])

    if(!authenticated){
      return(
        <div className='scanner-content'>
          <Sidebar />
          <div className='main-content'>
              <div className="login-cta-div" >
                <h3>Don't have an account? Create one now!</h3>
                <button>Sign In</button>
              </div>
            </div>
        </div>
      )
    }

    if(!subscribed){
      return(
        <div className='scanner-content'>
          <Sidebar />
          <div>
            <p>
              You are not subscribed...
            </p>
          </div>
        </div>
      )
    }

    return (
        <div className='scanner-content'>
            <Sidebar />
            <div className='main-content'>
              <div className='scanner-title-container'>
                  <h2>Bet Scanner</h2>
                  <div className='button-container'>
                      <button style={{width: "20%"}} onClick={() => {propogateBetData()}}>
                          {dataExists ? ("Update Data") : ("Search Bets")}
                      </button>
                      <button style={{width: "20%"}} onClick={() => {setSearchEngaged(!searchEngaged)}}>
                          {searchEngaged ? ("Hide Search") : ("Show Search")}
                      </button>
                  </div>
                  {searchEngaged ? (
                    <div className='button-container-two'>
                      <div className='dropdown'>
                        <button className={`dropdown-toggle ${keySearchOpen ? 'invisible' : ''}`} onClick={() => {setKeySearchOpen(!keySearchOpen)}}>
                          Menu
                        </button>
                        <ul className={`dropdown-menu ${keySearchOpen ? 'open' : ''}`}>
                          <li className='dropdown-item' onClick={() => {setKeySearchOpen(!keySearchOpen)}}>Close</li>
                          <li className="dropdown-item" onClick={() => {searchByKey("h2h")}} >Moneyline</li>
                          <li className="dropdown-item" onClick={() => {searchByKey("spreads")}} >Spreads</li>
                          <li className="dropdown-item" onClick={() => {searchByKey("totals")}} >Totals</li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {dataExists ? (
                    <div className='table-container'>
                        <table className='scanner-table'>
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Bet Key</th>
                                    <th>Fanduel</th>
                                    <th>DraftKings</th>
                                    <th>BetUS</th>
                                    <th>BetMGM</th>
                                    <th>BetRivers</th>
                                    <th>Bovada</th>
                                    <th>LowVig</th>
                                    <th>BetOnlineAG</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                  betData.map((bet) => {

                                    let { event, event_key } = bet;
                                    let markets = bet.sorted_lines

                                    let fanduel_line, draftkings_line, bovada_line, betrivers_line, betus_line, betmgm_line, lowvig_line, betonline_line;
                                    let [fanduel_exists, draftkings_exists, bovada_exists, betrivers_exists, betus_exists, betmgm_exists, lowvig_exists, betonline_exists] = Array(8).fill(false);

                                    console.log(fanduel_exists);

                                    if(markets['fanduel']){
                                      fanduel_line = markets["fanduel"]
                                      fanduel_exists = true;
                                      console.log(fanduel_exists)
                                    }
                                    if(markets['draftkings']){
                                      draftkings_line = markets["draftkings"]
                                      draftkings_exists = true;
                                    }
                                    if(markets['betus']){
                                      betus_line = markets["betus"]
                                      betus_exists = true;
                                    }
                                    if(markets['bovada']){
                                      bovada_line = markets["bovada"]
                                      bovada_exists = true
                                    }
                                    if(markets['betrivers']){
                                      betrivers_line = markets['betrivers']
                                      betrivers_exists = true
                                    }
                                    if(markets['betmgm']){
                                      betmgm_line = markets['betmgm']
                                      betmgm_exists = true
                                    }
                                    if(markets['lowvig']){
                                      lowvig_line = markets['lowvig']
                                      lowvig_exists = true
                                    }
                                    if(markets['betonlineag']){
                                      betonline_line = markets['betonlineag']
                                      betonline_exists = true
                                    }

                                    const uuid = uuidv4();
                                                                                
                                    return (
                                        <tr key={uuid}>
                                            <td>
                                                <div className='cell-content'>
                                                    <p>{event}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='cell-content'>
                                                    <p>{auditBetKey(event_key)}</p>
                                                </div>
                                            </td>
                                            { fanduel_exists ? (
                                              <td className='bet-content' >
                                                <div className='cell-content'>
                                                    <p>{fanduel_line.outcomes[0][0].price}</p>
                                                    <p>{fanduel_line.outcomes[0][1].price}</p>
                                                </div>
                                              </td>
                                            ) : (
                                              <td className='no-content' >
                                                <div className='cell-content'>
                                                  <p>
                                                    No line
                                                  </p>
                                                </div>
                                              </td>
                                            )}
                                            { draftkings_exists ? (
                                              <td className='bet-content' >
                                                <div className='cell-content'>
                                                  <p>{draftkings_line.outcomes[0][0].price}</p>
                                                  <p>{draftkings_line.outcomes[0][1].price}</p>
                                                </div>
                                              </td>
                                            ) : (
                                              <td className='no-content' >
                                                <div className='cell-content'>
                                                  <p>
                                                    No line
                                                  </p>
                                                </div>
                                              </td>
                                            )}
                                            { betus_exists ? (
                                              <td className='bet-content' >
                                                <div className='cell-content'>
                                                  <p>{betus_line.outcomes[0][0].price}</p>
                                                  <p>{betus_line.outcomes[0][1].price}</p>
                                                </div>
                                              </td>
                                            ) : (
                                              <td className='no-content' >
                                                <div className='cell-content'>
                                                  <p>
                                                    No line
                                                  </p>
                                                </div>
                                              </td>
                                            )}
                                            { betmgm_exists ? (
                                              <td className='bet-content' >
                                                <div className='cell-content'>
                                                  <p>{betmgm_line.outcomes[0][0].price}</p>
                                                  <p>{betmgm_line.outcomes[0][1].price}</p>
                                                </div>
                                              </td>
                                            ) : (
                                              <td className='no-content' >
                                                <div className='cell-content'>
                                                  <p>
                                                    No line
                                                  </p>
                                                </div>
                                              </td>
                                            )}
                                            { betrivers_exists ? (
                                              <td className='bet-content' >
                                                <div className='cell-content'>
                                                  <p>{betrivers_line.outcomes[0][0].price}</p>
                                                  <p>{betrivers_line.outcomes[0][1].price}</p>
                                                </div>
                                              </td>
                                            ) : (
                                              <td className='no-content' >
                                                <div className='cell-content'>
                                                  <p>
                                                    No line
                                                  </p>
                                                </div>
                                              </td>
                                            )}
                                            { bovada_exists ? (
                                              <td className='bet-content' >
                                                <div className='cell-content'>
                                                  <p>{bovada_line.outcomes[0][0].price}</p>
                                                  <p>{bovada_line.outcomes[0][1].price}</p>
                                                </div>
                                              </td>
                                            ) : (
                                              <td className='no-content' >
                                                <div className='cell-content'>
                                                  <p>
                                                    No line
                                                  </p>
                                                </div>
                                              </td>
                                            )}
                                            { lowvig_exists ? (
                                              <td className='bet-content' >
                                                <div className='cell-content'>
                                                  <p>{lowvig_line.outcomes[0][0].price}</p>
                                                  <p>{lowvig_line.outcomes[0][1].price}</p>
                                                </div>
                                              </td>
                                            ) : (
                                              <td className='no-content' >
                                                <div className='cell-content'>
                                                  <p>
                                                    No line
                                                  </p>
                                                </div>
                                              </td>
                                            )}
                                            { betonline_exists ? (
                                              <td className='bet-content' >
                                                <div className='cell-content'>
                                                  <p>{betonline_line.outcomes[0][0].price}</p>
                                                  <p>{betonline_line.outcomes[0][1].price}</p>
                                                </div>
                                              </td>
                                            ) : (
                                              <td className='no-content' >
                                                <div className='cell-content'>
                                                  <p>
                                                    No line
                                                  </p>
                                                </div>
                                              </td>
                                            )}
                                        </tr>
                                    )
                                  }
                              )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div style={{position: 'relative'}}>
                      <p>No Data Loaded Yet</p>
                    </div>
                )}
            </div>
            
        </div>
    )
}
