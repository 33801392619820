import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import { useCallback } from 'react';
import './style-pages/profile.css';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';
import { useNavigate, createSearchParams } from 'react-router-dom';

import heart_white from './assets/heart_white.svg';
import reply from './assets/reply.svg';
import trash from './assets/trash.svg';

export default function Profile() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');

    const [authenticated, setAuthentication] = useState(false);
    const [user, setUser] = useState({});
    const [userPosts, setUserPosts] = useState([]);
    const [dataExists, setDataExists] = useState(false);
    const [userPostsExist, setUserPostsExist] = useState(false);
    const [userPostsLength, setUserPostsLength] = useState(0);
    const [teamsFollowedLength, setTeamsFollowedLength] = useState(0);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if(user){
          setAuthentication(true);
          getUserProfile(user.uid)
        } else {
          console.log("no user :(");
        }
      })
    }, [])

    const getUserProfile = async (gid) => {
      try{
        const response = await axios.post("https://propicks-data-api.com/get-user-with-posts", {
          user_gid: gid,
        })

        setUser(response.data.user_info);

        if(response.data.user_posts.post_data){
          setUserPosts(response.data.user_posts.post_data.reverse())
          setUserPostsLength(response.data.user_posts.post_data.length)
          if(response.data.user_posts.post_data.length > 0){
            setUserPostsExist(true)
          }
        }

        if(response.data.user_info.teams_followed){
          setTeamsFollowedLength(response.data.user_info.teams_followed.length)
        }

        setDataExists(true);
        setDisabled(false);
      } catch (err) {
        console.log({ err: err.message })
      }
    };

    const openChat = (chat_id) => {
      const newParams = createSearchParams({
          id: chat_id
      });
      
      navigate({
          pathname: '/chat',
          search: `?${newParams.toString()}`
        });
    }

    const auditBetKey = (bet_key) => {
      if(bet_key == 'h2h') return "Moneyline"
      else if (bet_key == "spreads") return "Spreads"
      else if (bet_key == "totals") return "Totals"
    }

    const auditBetBook = (bet_book) => {
      if(bet_book == "fanduel") return "Fanduel"
      else if (bet_book == "draftkings") return "DraftKings"
      else if (bet_book == "mybookieag") return "MyBookieAG"
      else if (bet_book == "betrivers") return "BetRivers"
      else if (bet_book == "betmgm") return "BetMGM"
      else if (bet_book == "bovada") return "Bovada"
      else if (bet_book == "lowvig") return "LowVig"
      else if (bet_book == "betus") return "BetUS"
      else return bet_book;
    }

    const signOutUser = async () => {
      signOut(auth)
        .then(() => {
          sessionStorage.clear();
          navigate("/");
        })
        .catch((err) => {
          console.log({ err: err });
        })
    }

    const likeMessage = () => {

    }

    const deleteChat = async (message_id) => {
      console.log("delete chat called")
      try {
        const delete_response = await axios.post("https://propicks-data-api.com/delete-chat", {
          message_id: message_id,
          gid: user.gid
        })

        console.log(delete_response)

        if(delete_response.status == 200) {
          //userPosts.filter(chat => chat[0] !== message_id);
        }
      } catch (err) {
        console.log({ err: err.message })
      }
    }

    if(!authenticated){
        return(
          <div className='profile-content'>
            <Sidebar />
            <div className='main-content'>
              <div className="login-cta-div" >
                <h3>Don't have an account? Create one now!</h3>
                <button onClick={() => {navigate("/signin")}}>Sign In</button>
              </div>
            </div>
          </div>
        )
    }

    if(!dataExists) {
      return(
        <div className='profile-content'>
          <Sidebar />
          <div className='main-content'>
            <p>Loading...</p>
          </div>
        </div>
      )
    }

    if(authenticated && dataExists){
        return (
            <div className='profile-content'>
                <Sidebar />
                <main className='main-content'>
                    <div className='profile-header-div'>
                      <h2> {user.name} </h2>
                      <div className='counter-div'>
                        <h3> {userPostsLength} </h3>
                        <p> Posts </p>
                      </div>
                      <div className='counter-div'>
                        <h3> {teamsFollowedLength} </h3>
                        <p> Teams </p>
                      </div>
                    </div>
                    <div className='profile-mid-section'>
                      <div className='profile-sidebar'>
                        <div className='profile-sidebar-info'>
                          <div className='profile-sidebar-info-text-container'>
                            {user.bio ? (<p> {user.bio} </p>) : (<button onClick={() => {navigate("/change-bio")}}>Add Bio!</button>)}
                            <p> Email: {user.email}</p>
                            <p> Paid: {user.paid ? "Free" : "Paid"}</p>
                            <p> Tier: {user.tier} </p>
                          </div>
                          <div className='profile-button-container'>
                            <div className='profile-info-section-divider'></div>
                            <button classname="option-button" onClick={() => {navigate("/planManagement")}} > Manage Plan </button>
                            <button classname="option-button" onClick={() => {navigate("/settings")}} > Options </button>
                            <button classname="option-button" onClick={() => {signOutUser()}} > Log Out </button>
                          </div>
                        </div>
                      </div>
                      <div className='profile-chat-stream'>
                        { userPostsExist ? (
                          <>{
                            userPosts.map((chat) => {
                              let betting_line_exists = false;
                              let betting_line;
                              console.log(chat)

                              if(chat[6]){
                                  if(chat[6] != '{}'){
                                      betting_line = JSON.parse(chat[6]);
                                      betting_line_exists = true;
                                  }
                            }

                            return(
                                <div key={chat[0]} className='profile-team-feed-message' >
                                    <p className='chat-user' ><a style={{cursor: 'pointer'}} >{chat[1]}</a> {chat[8] ? <span style={{textDecoration: 'none', color: 'grey'}}> [Reply]</span> : <></>} </p>                                        
                                    <p className='chat-content' onClick={() => {openChat(chat[0])}} >{chat[3]}</p>
                                    { betting_line_exists ? (
                                          <div className='team-betting-market-chat'>
                                              <p className='team-betting-line-title' >{auditBetBook(betting_line.book)}</p>
                                              <p className='team-betting-line-title' >{auditBetKey(betting_line.key)}</p>
                                              <div className='team-betting-lines'>
                                                <div className='specific-line-display'>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][0].name}</p>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][0].price}</p>
                                                </div>
                                                <div className='specific-line-display'>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][1].name}</p>
                                                    <p className='team-betting-line-info' >{betting_line.betting_line[0][1].price}</p>
                                                </div>
                                              </div>
                                          </div>
                                        ): ( <></> )}
                                    <div className='button-div-chats' >
                                        <button className="chat-button-like" disabled={disabled} onClick={() => {likeMessage(chat[0])}} ><img src={heart_white} height="20" width="20" /></button>
                                        <button className="chat-button-reply" disabled={disabled} onClick={() => {openChat(chat[0])}} ><img src={reply} height="20" width="20" /></button>
                                        <button className="chat-button-delete" disabled={disabled} onClick={() => {deleteChat(chat[0])}} ><img src={trash} height="20" width="20" /></button>
                                    </div>
                                </div>
                            )
                          })}
                          </>) : (<div style={{margin: 25}}>
                            <p>You don't have any posts, join the conversation!</p>
                            <button onClick={() => {navigate("/explore")}}>Explore Page</button>
                          </div>)
                    }
                    </div>
                    </div>
                </main> 
            </div>
        )    
    }
    
}
