import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import axios from 'axios';
import './style-pages/changebio.css'

export default function ChangeBio() {
    const navigate = useNavigate();
    const session = sessionStorage.getItem('accountData');
    const auth = getAuth();

    const [bio, setBio] = useState("");
    const [user, setUser] = useState();
    const [dataExists, setDataExists] = useState(false);
    const [loading, setLoading] = useState(false);
    const [placeholder, setPlaceholder] = useState("Enter your new Bio!");

    const handleInputChange = (event) => {
        setBio(event.target.value);
    };

    const changeBio = async () => {
        setLoading(true);

        try {
            const bio_response = await axios.post("https://propicks-data-api.com/update-bio", {
                bio: bio, 
                gid: user.gid
            })

            if(bio_response.status == 200){
                setLoading(false)
                navigate("/profile");
            } else {
                setLoading(false)
                setPlaceholder("Operation failed, please try again another time.")
            }
        } catch (err) {
            console.log({ err: err.message })
        }
    }

    const getUserInfo = useCallback(async (gid) => {
        if (session != null) {
            const userInfo = JSON.parse(session)
            setUser(userInfo);
            setDataExists(true);
        } else {
          await axios.post("https://propicks-data-api.com/get-user", {
              gid: gid,
            })
              .then((response) => {
                setUser(response.data.postgres_response);
                setDataExists(true);
                sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
              })
              .catch((err) => {
                console.log({ err: err });
              })
        }
    });

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(user){
                getUserInfo(user.uid);
            } else {
                console.log("No user :(");
            }
        })
    }, [])

    if(!dataExists){
        return(
            <div className='bio-content' >
                <Sidebar />
                <div className='main-content'>
                    <p>Loading...</p>
                </div>
            </div>
        )
    }

    return(
        <div className='bio-content'>
            <Sidebar />
            <div className='main-content'>
                <h3>Change Bio:</h3>
                <input type='text' onChange={handleInputChange} placeholder={placeholder} className='bio-input' />
                <button onClick={() => {changeBio()}} className='bio-submit' >Submit</button>
                <p>{loading ? "Loading..." : ""}</p>
            </div>
        </div>
    )
}