import React, { useState, useEffect, useCallback } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default function StripeContainer(type, trial) {
    // This initiates the stripe.js package and the keys need to be correct for this.
    const stripePromise = loadStripe("pk_test_51PYtYZEgjsKDilRyDk9LvM9amBiC0fx6Nw0Xr7jYY859rXnoFCaLk5RJ4aqTpN9AxJVCHvPhLUAjtQQtR3GqNUzr006qslpjpH");
    const auth = getAuth();
    const uriSearch = new URLSearchParams(window.location.search);
    const session = sessionStorage.getItem('accountData');
    
    const [options, setOptions] = useState({});
    const [ready, setReady] = useState(false);
    const [header, setHeader] = useState();
    const [price, setPrice] = useState();
    const [prevPrice, setPrevPrice] = useState(null);
    const [prevToggle, setPrevToggle] = useState(false);
    const [error, setError] = useState();
    const [user, setUser] = useState();
    
    const tier = type.type;
    const trialNew = type.trial;

    const getUserInfo = useCallback(async (gid) => {
        if (session != null) {
            const userInfo = JSON.parse(session)

            console.log(userInfo)
            console.log(userInfo.stripe_id)

            setUser(userInfo);
            createSetup(userInfo.stripe_id)
        } else {
          await axios.post("https://propicks-data-api.com/get-user", {
              gid: gid,
            })
              .then((response) => {
                setUser(response.data.stripe_response.stripe_id);
                sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
                createSetup(response.data.postgres_response.stripe_id);
              })
              .catch((err) => {
                console.log({ err: err });
              })
        }
    });

    const createSetup = useCallback(async (id) => {
        await axios.post('https://propicks-data-api.com/create-setup', {
            customer: id
        })
            .then((response) => {
                setOptions({
                    clientSecret: response.data.clientSecret
                })
                setReady(true);
                console.log("Setup Created Successfully: ", response.data.clientSecret)
            })
            .catch((err) => {
                console.log({ message: err.message });
            });
    }, [])

    
    useEffect(() => {
        const error = uriSearch.get('error');
        if(error) {
            setError(error);
        }

        onAuthStateChanged(auth, (user) => {
            let payID;
            switch(tier){
                case "0":
                    setHeader("Personal");
                    setPrice("Free");
                    setPrevPrice("$4.99");
                    setPrevToggle(true);
                    payID = 5;
                    break;
                case "1":
                    setHeader("Professional");
                    setPrice("$14.99 ");
                    payID = 1;
                    break;
                case "2":
                    setHeader("Enterprise");
                    setPrice("$49.99");
                    payID = 2;
                    break;
                default:
                    console.log("No Type Passed -- Needs Type [0-2]");
                    break;
            }

            if(user){
                getUserInfo(user.uid)
            } else {
                console.log("No user signed in :(")
            }
        });
    }, []);
    

    

    if(!ready){
        return(
            <div
                style={{
                    height: 500,
                }}
            >
                <h2>
                    Loading...
                </h2>
            </div>
        )
    }
    
    return(
        <div
            style={{
                position: 'relative',
                left: '30%',
                marginBottom: 50,
            }}
        >
            <div
                style={{
                    width: '40%',
                    border: '1px solid white',
                    display: 'flex',
                    flexDirection: 'column',
                    background: 'black',
                    padding: 20,
                    borderRadius: 5,
                }}
            >
                <h2
                    style={{
                        color: 'white',
                        textAlign: 'left',
                        marginLeft: 15,
                        marginRight: 15,
                        marginBottom: 0, 
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 300,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    {header} <div><span style={{fontFamily: 'Inter Tight, sans-serif', fontWeight: 500}}> {price} { prevToggle ? (<><s style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,}} >{prevPrice}</s></>) : "" } <span style={{fontSize: 12}}>/Monthly</span></span></div>
                </h2>
                { trialNew ? (
                    <h3
                    style={{
                        textAlign: 'left',
                        color: 'white',
                        paddingLeft: 20,
                        fontFamily: 'Inter, sans-serif',
                    }}
                >
                    Start your 7 day Free Trial Today!
                </h3>
                ) : (
                    <h3
                    style={{
                        textAlign: 'left',
                        color: 'white',
                        paddingLeft: 20,
                        height: 10,
                        marginTop: 0,
                        marginBottom: 0
                    }}
                >
                    
                </h3>) }
                
                <Elements 
                    stripe={stripePromise}
                    options={options}
                >
                    <PaymentForm type={tier}/>
                </Elements>
            </div>
        </div>
    )
}

/*
unused font elements: 
<link rel="preconnect" href="https://fonts.googleapis.com"/>
<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
<link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>

old docref code: 
const docRef = doc(db, "customers", user.uid);
let data = await getDoc(docRef);

if(data.exists()){
    const parsed = data.data();
    await axios.post('https://aiserver.herokuapp.com/createSetup', {
        customer: parsed.customerId.id
    })
        .then((response) => {
            setOptions({
                clientSecret: response.data.clientSecret
            })
            setReady(true);
        })
        .catch((err) => {
            console.log({ message: err.message });
        });
}
*/